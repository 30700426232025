import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ThemeService } from "./services/theme/theme.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(private theme: ThemeService, public translate: TranslateService) {
    translate.setDefaultLang("pt-br");

    const language = localStorage.getItem("language");

    let browserLang = translate.getBrowserLang();
    browserLang = browserLang === "pt" ? "pt-br" : browserLang;
    browserLang = browserLang?.match(/pt-br|en/) ? browserLang : "pt-br";
    translate.use(language || browserLang);
  }

  ngOnInit() {
    this.theme.loadCurrentTheme();
  }
}
