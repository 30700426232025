import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root"
})
export class SnackbarService {
  constructor(
    private snackbar: MatSnackBar,
    private translate: TranslateService
  ) {}

  duration = 3000;
  button = this.translate.instant("close");

  error(message: string) {
    this.snackbar.open(message, this.button, {
      duration: this.duration,
      panelClass: ["error"]
    });
  }

  info(message: string) {
    this.snackbar.open(message, this.button, {
      duration: this.duration,
      panelClass: ["info"]
    });
  }

  success(message: string) {
    this.snackbar.open(message, this.button, {
      duration: this.duration,
      panelClass: ["success"]
    });
  }
}
