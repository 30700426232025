import { DepartmentService } from "./../department/department.service";
import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpService } from "../http/http.service";
import { IPagedReq } from "src/app/models/utils";
import {
  ILevelAccess,
  IPermitions,
  IToken,
  IUser,
  IUserPemition,
} from "src/app/models/user";
import { Md5 } from "md5-typescript";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  constructor(
    private http: HttpService,
    private departamentService: DepartmentService
  ) {}

  getPagesPermitions() {
    return this.http.get<IPermitions[]>("core/list-pages/");
    // /core/update-default-permitions/
  }

  getUsers(
    page?: number,
    name?: string,
    level?: string,
    department?: string,
    contact?: string,

    is_active?: boolean
  ) {
    let query = new HttpParams()
      .set("page_size", environment.page_size)
      .set("page", page ?? "");
    if (name) query = query.set("name", name);
    if (level) query = query.set("level", level);
    if (department) query = query.set("department", department);
    if (contact) query = query.set("contact", contact);

    if (is_active)
      query = query.set(
        "is_active",
        typeof is_active === "boolean" ? is_active : ""
      );
    return this.http.get<IPagedReq<IUser>>("core/list-user/", query);
  }

  postUser(user: IUser) {
    console.log(user);
    const body = {
      type_user: user.type_user ?? null,
      level: user.level,
      email: user.email,
      name: user.name,
      username: user.email, // ? username is email
      phone: user.phone,
      phone_extension: user.phone_extension,
      department: user.department,
    };
    return this.http.post<IUser>("core/create-user/", body);
  }

  createPermitions(id: number, permit: any, token: string) {
    const query = new HttpParams().set("user", id).set("token", token);
    const body = permit;
    console.log(`core/create-pages-user/${query}/`, body);
    return this.http.post<IUser>(`core/create-pages-user/?${query}`, body);
  }

  updatetUser(user: IUser) {
    const body = {
      id: user.id,
      email: user.email,
      name: user.name,
      username: user.email, // ? username is e-mail
      phone: user.phone,
      phone_extension: user.phone_extension,
      department: user.department,
      level: user.level,
    };
    return this.http.patch<IUser[]>("core/update-user/", body);
  }

  deleteUser(id: number) {
    const query = new HttpParams().set("id", id);
    return this.http.delete<IUser[]>("core/delete-user/", query);
  }

  setPass(token: string, password: string) {
    const query = new HttpParams()
      .set("token", token)
      .set("password", Md5.init(password).toUpperCase());
    return this.http.post<IToken>("core/change-password/", query);
  }

  resetPass(email: string) {
    const body = new HttpParams().set("email", email);
    return this.http.post<string>("core/forgot-password/", body);
  }
}
