import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { AdminGuard } from "./guards/admin.guard";
import { AuthGuard } from "./guards/auth.guard";
import { UnauthGuard } from "./guards/unauth.guard";
import { CurvesComponent } from "./pages/curves/curves.component";

const SPR = false;

const routes: Routes = [
  {
    path: "login",
    canActivate: [UnauthGuard],
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "set-password/:token",
    loadChildren: () =>
      import("./pages/set-password/set-password.module").then(
        (m) => m.SetPasswordModule
      ),
  },
  {
    path: "",
    component: NavbarComponent,
    // canActivate: [AuthGuard], // * Caso o projeto tenha rotas sem auth além de login, remover essa linha.
    children: [
      { path: "", redirectTo: "monitoring", pathMatch: "full" },
      {
        path: "monitoring",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./pages/monitoring/monitoring.module").then(
            (m) => m.MonitoringModule
          ),
      },
      {
        path: "beds",
        canActivate: [AuthGuard, AdminGuard],
        loadChildren: () =>
          import("./pages/beds/beds.module").then((m) => m.BedsModule),
      },
      {
        path: "patients",
        canActivate: [AuthGuard, AdminGuard],
        loadChildren: () =>
          import("./pages/patients/patients.module").then(
            (m) => m.PatientsModule
          ),
      },
      {
        path: "equipment",
        canActivate: [AuthGuard, AdminGuard],
        loadChildren: () =>
          import("./pages/equipment/equipment.module").then(
            (m) => m.EquipmentModule
          ),
      },
      {
        path: "alarms",
        canActivate: [AuthGuard, AdminGuard],
        loadChildren: () =>
          import("./pages/alarms/alarms.module").then((m) => m.AlarmsModule),
      },
      {
        path: "active-alarms",
        canActivate: [AuthGuard, AdminGuard],
        loadChildren: () =>
          import("./pages/active-alarms/active-alarms.module").then(
            (m) => m.ActiveAlarmsModule
          ),
      },
      {
        path: "alarm-config",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./pages/alarms/alarm-config/alarm-config.module").then(
            (m) => m.AlarmConfigModule
          ),
      },
      {
        path: "historic",
        canActivate: [AuthGuard, AdminGuard],
        loadChildren: () =>
          import("./pages/historic/historic.module").then(
            (m) => m.HistoricModule
          ),
      },
      {
        path: "dictionary-params",
        canActivate: [AuthGuard, AdminGuard],
        loadChildren: () =>
          import("./pages/dictionary-params/dictionary-params.module").then(
            (m) => m.DictionaryParamsModule
          ),
      },
      {
        path: "users",
        canActivate: [AuthGuard, AdminGuard],
        loadChildren: () =>
          import("./pages/users/users.module").then((m) => m.UsersModule),
      },
      {
        path: "equipment-configuration",
        canActivate: [AuthGuard, AdminGuard],
        loadChildren: () =>
          import(
            "./pages/equipment-configuration/equipment-configuration.module"
          ).then((m) => m.EquipmentConfigurationModule),
      },
      {
        path: "bed-department-config",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            "./pages/beds/bed-department-configuration/bed-department-configuration.module"
          ).then((m) => m.BedDepartmentConfigurationModule),
      },
      {
        path: "patient-display/:id",
        canActivate: [AuthGuard /* AdminGuard */],
        loadChildren: () =>
          import("./pages/patient-display/patient-display.module").then(
            (m) => m.PatientDisplayModule
          ),
      },
      {
        path: "trends/:id",
        canActivate: [AuthGuard, AdminGuard],
        loadChildren: () =>
          import("./pages/trends/trends.module").then((m) => m.TrendsModule),
      },
      {
        path: "system-settings",
        canActivate: [AuthGuard, AdminGuard],
        loadChildren: () =>
          import("./pages/system-settings/system-settings.module").then(
            (m) => m.SystemSettingsModule
          ),
      },
      {
        path: "terms-of-use",
        loadChildren: () =>
          import("./pages/terms-or-use/terms-or-use.module").then(
            (m) => m.TermsOrUseModule
          ),
      },
      {
        path: "curves",
        component: CurvesComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: SPR ? "enabled" : "disabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
