<nav [ngClass]="{hidden}">
  <div class="content">
    <a class="logo" routerLink="/">
      <img src="assets/images/skopien_branco.svg" alt="logo" />
    </a>

    <div class="clock">
      <p class="name f-15" *ngIf="config?.system_settings?.hospital">
        {{ config.system_settings.hospital }}
      </p>
      <p>{{ now | async | fDate : "datetime" }}</p>

      <div class="avatar">
        <avatar
          [name]="user.name"
          color="dark"
          bg="#fff"
          *ngIf="user.name"
        ></avatar>
      </div>
    </div>
  </div>
</nav>

<mat-drawer-container>
  <mat-drawer #drawer [opened]="!hidden" mode="side">
    <div class="nav-list">
      <button
        *ngIf="checkAccess('monitoring')"
        title="home"
        mat-icon-button
        matTooltip="Monitores"
        matTooltipPosition="after"
        routerLink="/monitoring"
        routerLinkActive="active"
      >
        <i icon="home"></i>
      </button>

      <button
        *ngIf="checkAccess('beds')"
        title="beds"
        mat-icon-button
        [matTooltip]="'bed.title' | translate"
        matTooltipPosition="after"
        routerLink="/beds"
        routerLinkActive="active"
      >
        <i icon="beds"></i>
      </button>

      <button
        *ngIf="checkAccess('patients')"
        title="patients"
        mat-icon-button
        matTooltip="Pacientes"
        matTooltipPosition="after"
        routerLink="/patients"
        routerLinkActive="active"
      >
        <i icon="patients"></i>
      </button>

      <button
        *ngIf="checkAccess('equipment')"
        title="equipment"
        mat-icon-button
        matTooltip="Equipamentos"
        matTooltipPosition="after"
        routerLink="/equipment"
        routerLinkActive="active"
      >
        <i icon="equipment"></i>
      </button>

      <button
        *ngIf="checkAccess('alarms')"
        title="alarms"
        mat-icon-button
        [matTooltip]="'alarm.title' | translate"
        matTooltipPosition="after"
        routerLink="/alarms"
        routerLinkActive="active"
      >
        <i icon="alarms"></i>
      </button>

      <button
        title="active_alarms"
        mat-icon-button
        [matTooltip]="'alarm.active_alarms.title' | translate"
        matTooltipPosition="after"
        routerLink="/active-alarms"
        routerLinkActive="active"
      >
        <i icon="alarm_test"></i>
      </button>

      <button
        *ngIf="checkAccess('historic')"
        title="history"
        mat-icon-button
        [matTooltip]="'historic.title' | translate"
        matTooltipPosition="after"
        routerLink="/historic"
        routerLinkActive="active"
      >
        <i icon="history"></i>
      </button>

      <button
        *ngIf="checkAccess('users')"
        title="users"
        mat-icon-button
        matTooltip="Usuários"
        matTooltipPosition="after"
        routerLink="/users"
        routerLinkActive="active"
      >
        <i icon="users"></i>
      </button>

      <button
        *ngIf="checkAccess('dictionary-params')"
        title="dictionary-params"
        mat-icon-button
        [matTooltip]="'dictionary.title' | translate"
        matTooltipPosition="after"
        routerLink="/dictionary-params"
        routerLinkActive="active"
      >
        <i icon="dictionary"></i>
      </button>

      <button
        *ngIf="checkAccess('system-settings')"
        title="system_settings"
        mat-icon-button
        matTooltip="Configurações de Sistema"
        matTooltipPosition="after"
        routerLink="/system-settings"
        routerLinkActive="active"
      >
        <i icon="system_settings"></i>
      </button>

      <button
        title="exit"
        class="exit-icon"
        mat-icon-button
        [matTooltip]="'config_channel.exit' | translate"
        matTooltipPosition="after"
        (click)="logout()"
      >
        <i icon="exit"></i>
      </button>
    </div>
  </mat-drawer>

  <mat-drawer-content [ngClass]="{hidden}">
    <router-outlet *ngIf="!loading"></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
